// Variable
$red: #e52314;
$yellow: #fcd406;
$black: #000000;
$white: #ffffff;

// Mixins
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
}

// Hide XMS toolbar
#cms-top {
    // display: none;
}

html {
    margin-top: 0 !important;
}

body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    @include font-size(1);
    overflow-x: hidden;

    a {
        color: inherit;
        text-decoration: none;
    }

    a.button {
        width: 250px;
        margin: 30px 20px 0 0;
        padding: 15px 20px;
        border: 2px solid $yellow;
        color: $yellow;
        @include font-size(1.5);
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        transition: background-color 0.2s;
    }

    a.button.solid,
    a.button:hover {
        background-color: $yellow;
        color: $black;
    }

    a.btn {
        margin: 30px 0 0 0;
        padding: 15px 20px;
        border: 2px solid $yellow;
        background-color: $yellow;
        color: $black;
        @include font-size(1.5);
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.2s;
        display: block;
        .solid,
        &:hover {
            background-color: $black;
            border: 2px solid $black;
            color: $white;
        }
    }
}

/* sticky nav fix for cms toolbar */
.cms-toolbar-expanded .sticky {
    top: 46px !important;
}

.sitecontainer {
    max-width: 1300px;
    margin: auto;
    background: $white;
}

.SI-link {
    padding: 10px 30px;
    background-color: $black;
    color: $white;
    @media (max-width: 800px) {
        padding: 1vw 2vw;
    }

    p {
        margin: 0;
        @include font-size(1);
        font-weight: 600;
        text-align: center;
        line-height: 25px;

        @media (max-width: 800px) {
            font-size: 2vw;
            line-height: 1.5;
        }

        a {
            text-decoration: underline;
        }
    }
}

.smith-box {
    width: 120px;
    padding: 10px 12px;
    background-color: $black;
    color: $white;
    position: absolute;
    top: 15px;
    left: 25px;
    text-align: left;
    display: block;
    z-index: 9;

    @media (max-width: 1000px) {
        margin-right: 20px;
        position: relative;
        top: inherit;
        left: inherit;
        display: inline-block;
        vertical-align: middle;
    }

    @media (max-width: 800px) {
        width: auto;
        margin: 5vw;
    }

    div {
        margin: 0;
        @include font-size(1.4);
        font-weight: 600;
        line-height: 1;
        @media (max-width: 800px) {
            @include font-size(1);
        }
        &:last-of-type {
            margin: 10px 0 5px;
            @include font-size(1);
            font-weight: 500;
            @media (max-width: 800px) {
                margin: 5px 0 2px;
                @include font-size(0.8);
            }
        }
    }
}

.nav-wrapper {
    box-shadow: 0 5px 5px -5px #ccc;
    position: relative;
    z-index: 999999;

    @media (max-width: 800px) {
        box-shadow: unset;
        position: absolute;
        left: 0;
        right: 0;
    }

    &.stickyNav {
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;

        @media (max-width: 800px) {
            height: 42px;
        }
    }

    nav {
        max-width: 1000px;
        margin: auto;
        padding-left: 250px;
        text-align: center;
        position: relative;
        z-index: 999999;
        @media (max-width: 1000px) {
            padding-left: 0;
        }
        @media (max-width: 800px) {
            text-align: left;
        }

        i.fa-bars {
            color: white;
            font-size: 25px;
            position: absolute;
            top: 5vw;
            right: 5vw;
            cursor: pointer;
            display: none;
            z-index: 999;

            @media (max-width: 800px) {
                display: block;
            }
        }

        .nav-links {
            display: inline-block;
            @media (max-width: 800px) {
                padding-top: 50px;
                background-color: rgba(0, 0, 0, 0.9);
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 9999;
                display: none;
            }

            i.fa-times {
                color: $white;
                @include font-size(2);
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;
                display: none;
                @media (max-width: 800px) {
                    display: block;
                }
            }

            .menu-option {
                padding: 50px 50px;
                font-weight: 700;
                text-transform: uppercase;
                display: inline-block;
                cursor: pointer;

                &.travel-menu {
                    @media (max-width: 800px) {
                        .submenu {
                            height: 60vh;
                        }
                    }
                }

                &.donate {
                    @media (max-width: 800px) {
                        padding: 12px 0;

                        a {
                            padding: 0;
                            display: inline-block;
                        }

                        .submenu.open {
                            height: 40vh;
                        }
                    }
                }
                &:hover {
                    padding-bottom: 45px;
                    background: #fff;
                    border-bottom: 5px solid #000;
                }

                @media (max-width: 1300px) {
                    margin: 0;
                    padding: 50px 30px;
                }

                @media (max-width: 1000px) {
                    padding: 50px 20px;
                }

                @media (max-width: 800px) {
                    margin: auto;
                    padding: 12px 0;
                    color: $white;
                    text-align: center;
                    display: block;
                    &:hover {
                        padding-bottom: 12px;
                        background-color: transparent;
                        border-bottom: none;
                    }
                }

                i.fa-caret-down {
                    margin: -4px 0 0 5px;
                    @include font-size(1.5);
                    vertical-align: top;
                    display: none;
                    @media (max-width: 800px) {
                        display: inline-block;
                    }
                }

                .submenu {
                    padding: 20px 25px;
                    background-color: #fff;
                    box-shadow: 0 0 5px #e9e9e9;
                    border: 1px solid #e9e9e9;
                    border-top: 0;
                    text-align: center;
                    position: absolute;
                    top: 119px;
                    left: -425px;
                    right: -425px;
                    display: none;
                    z-index: 8;

                    @media (max-width: 800px) {
                        // height: 75vh;
                        max-height: 0;
                        margin-top: 0;
                        padding: 0 25px;
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                        overflow-y: hidden;
                        position: relative;
                        top: inherit;
                        left: inherit;
                        right: inherit;
                        display: block;
                        z-index: 999;
                    }
                    &.open {
                        max-height: none;
                        padding-bottom: 50px;
                    }

                    i.fa-times {
                        display: none;
                        @media (max-width: 800px) {
                            left: 50px;
                            display: block;
                        }
                    }

                    .submenu-section {
                        width: 275px;
                        padding: 50px 0 50px 30px;
                        text-align: left;
                        display: inline-block;
                        vertical-align: top;

                        @media (max-width: 1300px) {
                            width: auto;
                            margin: 50px 25px;
                        }

                        @media (max-width: 1100px) {
                            max-width: 18vw;
                            margin: 3vw 1vw;
                        }

                        @media (max-width: 900px) {
                            margin: 20px 14px;
                        }

                        @media (max-width: 800px) {
                            width: auto;
                            max-width: none;
                            margin: 10px auto 0;
                            padding: 0 10px;
                            text-align: center;
                            display: block;
                        }
                        &.bl {
                            border-left: 1px solid #ccc;
                            @media (max-width: 800px) {
                                border-left: none;
                            }
                        }
                        &.alb {
                            vertical-align: bottom;
                            @media (max-width: 1200px) {
                                vertical-align: top;
                            }
                        }

                        strong,
                        a {
                            margin: 0 0 12px;
                            padding: 0;
                            @include font-size(0.95);
                            font-weight: 300;
                            text-transform: none;
                            display: block;
                            @media (max-width: 800px) {
                                color: #fff;
                            }
                            &:hover {
                                border-bottom: none;
                                text-decoration: underline;
                            }
                            &:last-of-type {
                                color: inherit;
                            }

                            img {
                                max-width: 100%;

                                @media (max-width: 1100px) {
                                    height: auto;
                                }

                                @media (max-width: 800px) {
                                    display: none;
                                }
                            }
                        }
                        strong {
                            &:hover {
                                text-decoration: none;
                                cursor: default;
                            }
                        }

                        a:hover {
                            text-decoration: underline;
                        }

                        strong {
                            font-weight: bold;
                            @media (max-width: 800px) {
                                text-align: center;
                            }
                        }
                        .social-icons {
                            @media (max-width: 800px) {
                                text-align: center;
                            }
                            a {
                                display: inline;
                                font-size: 18px;
                                @media (max-width: 800px) {
                                    color: #fff;
                                }
                                &:hover {
                                    color: #333;
                                }
                            }
                        }
                    }
                }

                .travel .submenu-section {
                    width: 215px;
                    @media (max-width: 1300px) {
                        width: 15vw;
                        margin: 0;
                        padding: 3vw 1.5vw;
                    }
                    @media (max-width: 800px) {
                        width: auto;
                        padding: 10px;
                    }
                }

                &.narrow-submenu {
                    position: relative;

                    a {
                        padding: 0;
                        color: $black;
                    }

                    .submenu {
                        left: 0;
                        right: 0;
                        @media (max-width: 1000px) {
                            padding: 20px 0 10px;
                            top: 130px;
                            left: -10px;
                            right: -10px;
                        }
                        @media (max-width: 800px) {
                            margin-top: 0;
                            padding: 0;
                            background-color: transparent;
                            border: none;
                            box-shadow: none;
                            position: relative;
                            top: inherit;
                            left: inherit;
                            right: inherit;
                            display: block;
                            z-index: 9;
                        }

                        .submenu-section {
                            width: auto;
                            margin: 0 auto;
                            padding: 0;
                            @media (max-width: 800px) {
                                margin-top: 12px;
                            }
                            a:last-of-type {
                                margin-bottom: 5px !important;
                            }
                        }
                    }
                }
            }

            a:last-of-type {
                color: $red;
            }

            .menu-option:hover .submenu {
                display: block;
                @media (max-width: 800px) {
                    display: none;
                }
            }
        }
    }
}

.two-column-grid {
    max-width: 1160px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 50px;
    }

    img {
        width: 100%;
        margin-bottom: 15px;
    }

    p {
        margin: 10px auto;
        font-size: 21px;

        @media (max-width: 600px) {
            margin-top: 0;
            font-size: 4.2vw;
        }
    }

    p.section-link {
        font-weight: bold;
    }
}

.promobanner {
    font-size: 0;
    position: relative;

    .fa-times {
        color: white;
        font-size: 1.2rem;
        font-weight: lighter;
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;

        @media (max-width: 800px) {
            font-size: 2vw;
            top: 1vw;
            right: 1vw;
        }
    }
}

.billboard-slideshow-wrapper {
    height: auto;
    text-align: center;
    position: relative;
    white-space: nowrap;

    @media (max-width: 800px) {
        height: 600px;
    }

    i.fa {
        color: #ccc;
        @include font-size(3);
        text-shadow: 0 0 10px $black;
        cursor: pointer;
        position: absolute;
        top: 240px;
        left: 20px;
        display: none;
        z-index: 9;
        @media (max-width: 1300px) {
            top: 17vw;
        }
        @media (max-width: 1000px) {
            @include font-size(2);
            left: 5px;
        }
        @media (max-width: 400px) {
            opacity: 0.5;
            left: 2px;
        }
        &:hover {
            color: $white;
        }
        &.fa-chevron-right {
            left: inherit;
            right: 20px;
            @media (max-width: 1000px) {
                right: 5px;
            }
            @media (max-width: 400px) {
                right: 2px;
            }
        }
    }

    .slideshow-controls {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        @media (max-width: 1000px) {
            bottom: -35px;
        }

        .slideshow-control {
            width: 20px;
            height: 20px;
            margin: 0 5px;
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 100%;
            box-shadow: 0 0 5px #000;
            position: relative;
            display: inline-block;
            cursor: pointer;
            transition: all 0.2s;
            @media (max-width: 1000px) {
                background-color: #fff;
                border: 3px solid #ccc;
                box-shadow: none;
            }
            &:hover,
            &.active {
                background-color: #fff;
                @media (max-width: 1000px) {
                    background-color: #666;
                    border: 3px solid #666;
                }
            }
        }
    }

    .billboard-slideshow {
        font-size: 0;

        .billboard-slide {
            text-align: left;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: none;
            overflow: hidden;

            &:first-of-type {
                display: block;
            }

            img {
                width: 100%;
                height: 560px;

                @media (max-width: 1300px) {
                    height: 43vw;
                }

                @media (max-width: 800px) {
                    display: none;
                }
            }

            .billboard-text {
                max-width: 42%;
                padding: 0 130px 80px;
                background-image: linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0.8),
                    rgba(0, 0, 0, 0)
                );
                color: $white;
                white-space: normal;
                position: absolute;
                top: 0;
                bottom: 0;

                @media (max-width: 1000px) {
                    padding-left: 8vw;
                }

                h2 {
                    margin: 0;
                    @include font-size(3);
                    text-transform: uppercase;
                    @media (max-width: 800px) {
                        font-size: 5.5vw;
                    }
                }

                h3 {
                    margin: 200px 0 10px;
                    @include font-size(2);
                    line-height: 1.3;

                    @media (max-width: 1300px) {
                        margin: 12vw 0 0;
                    }

                    @media (max-width: 1000px) {
                        font-size: 3vw;
                    }
                }

                p {
                    color: #f3f3f3;
                    @include font-size(1.1);
                    font-weight: 300;
                    @media (max-width: 800px) {
                        margin: 1vw 0;
                        font-size: 2.5vw;
                    }
                }

                a.button {
                    @media (max-width: 800px) {
                        width: auto;
                        margin-top: 1.5vw;
                        font-size: 2.5vw;
                    }
                    @media (max-width: 400px) {
                        padding: 3vw;
                    }
                }
            }
        }
    }
}

section {
    max-width: 1300px;
    margin: auto;

    h2 {
        @include font-size(2.5);
    }

    .billboard-link {
        width: 100%;
        min-height: 550px;
        margin-bottom: 50px;
        background-color: black;
        position: relative;

        @media (max-width: 600px) {
            background-color: unset;
        }

        img {
            width: 1300px;
            height: 550px;
            position: absolute;
            top: 0;
            right: 0;

            @media (max-width: 600px) {
                width: 100%;
                height: auto;
                position: relative;
            }
        }

        .text-wrapper {
            padding: 140px 100px 140px 70px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            @media (max-width: 600px) {
                margin-top: -30vw;
                padding: 50vw 10vw 14vw;
                background: linear-gradient(
                    to top,
                    rgba(0, 0, 0, 1) 0,
                    rgba(0, 0, 0, 1) 75%,
                    rgba(0, 0, 0, 0) 100%
                );
                position: relative;
            }

            h2 {
                margin: 0;
                max-width: 350px;
                color: white;
                font-size: 36px;

                @media (max-width: 600px) {
                    max-width: 100%;
                    font-size: 6.5vw;
                }
            }

            p {
                max-width: 350px;
                margin-bottom: 40px;
                color: white;
                font-size: 1.3rem;
                line-height: 1.4;

                @media (max-width: 600px) {
                    max-width: 100%;
                    font-size: 4.5vw;
                }
            }

            a {
                width: 320px;
                padding: 20px;
                border: 5px solid $yellow;
                color: $yellow;
                font-size: 1.5rem;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                display: block;
                transition: background-color 0.3s;

                @media (max-width: 600px) {
                    width: 100%;
                    padding: 3vw 0;
                    border-width: 3px;
                    font-size: 4.5vw;
                }

                &:hover {
                    background-color: $yellow;
                    color: black;
                }
            }
        }
    }

    .title-link {
        max-width: 1160px;
        margin: auto;
        padding: 20px;

        @media (max-width: 800px) {
            padding: 0 20px;
        }

        h2 {
            margin: 0;
            font-size: 1.5rem;
            display: inline-block;
        }

        a {
            margin-top: -7px;
            padding-right: 5px;
            @include font-size(1.1);
            font-weight: 500;
            line-height: 2.5;
            float: right;

            @media (max-width: 600px) {
                float: none;
                display: block;
            }

            &:hover {
                text-decoration: underline;
            }

            .right-arrow {
                width: 50px;
                margin-left: 15px;
                border-top: 2px solid black;
                display: inline-block;
                vertical-align: middle;
                position: relative;

                &::after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 10px solid black;
                    display: inline-block;
                    position: absolute;
                    top: -6px;
                    right: -2px;
                }
            }
        }
    }
}

section#section-links {
    max-width: 1160px;
    margin: 30px auto 100px;
    padding: 0 20px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;

    @media (max-width: 800px) {
        margin-bottom: 60px;
        grid-template-columns: repeat(2, 1fr);
    }

    a.button {
        width: 90%;
        margin: 0 auto;
        padding: 40px 10px;
        background-color: black;
        font-size: 1.7rem;
        text-transform: uppercase;
        color: white;
        border: none;

        @media (max-width: 400px) {
            padding: 5vw 10px;
            font-size: 5vw;
        }
    }
}

section#image-links {
    max-width: 1300px;
    margin: auto;
    padding: 55px 0;
    text-align: center;

    a {
        width: 280px;
        margin: 15px 12px;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        align-conter: flex-start;
        transition: box-shadow 0.2s;

        img {
            width: 100%;
        }

        h3 {
            width: 100%;
            height: 120px;
            margin: 0;
            padding: 20px 30px;
            background-color: $black;
            color: $white;
            @include font-size(1.3);
            font-weight: 600;
            display: flex;
            align-self: center;
            font-weight: normal;
            span {
                align-self: center;
                strong {
                    font-weight: bold;
                }
            }
        }
    }
}

section#shop {
    padding-bottom: 75px;

    .product-categories {
        max-width: 1160px;
        margin: 0 auto;
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 25px;

        @media (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }

        a {
            font-size: 0;
            overflow: hidden;
            position: relative;
            transition: box-shadow 0.5s;

            @media (max-width: 600px) {
                display: block;
            }

            &:first-of-type {
                grid-column: 1 / span 2;
                grid-row: 1 / span 2;

                h3 {
                    padding: 20px 40px;
                    font-size: 2rem;

                    @media (max-width: 800px) {
                        padding: 3vw 8vw;
                        font-size: 5vw;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @media (max-width: 600px) {
                    height: auto;
                }
            }

            h3 {
                margin: 0;
                padding: 10px 20px;
                background: black;
                color: white;
                font-size: 1.3rem;
                font-weight: 600;
                position: absolute;
                bottom: -100px;
                left: 0;
                right: 0;
                z-index: 9;
                transition: bottom 0.3s ease-out;

                @media (max-width: 800px) {
                    padding: 2vw 3vw;
                    font-size: 3.5vw;
                    bottom: 0;
                }
            }
        }

        a:hover h3 {
            bottom: 0;
        }
    }

    .product-carousel-wrapper {
        margin-top: 30px;
        text-align: center;
        position: relative;

        a {
            width: 250px;
            margin: 20px;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            white-space: normal;

            img {
                width: 100%;
                height: auto;
            }

            h4 {
                margin: 10px 0;
                @include font-size(1.1);
                font-weight: 500;
            }

            h5 {
                margin: 0;
                color: #555;
                @include font-size(1.2);
                font-weight: 500;
            }
        }
    }

    .product-carousel-arrows {
        position: relative;
        display: none;
        @media (max-width: 1000px) {
            display: none;
        }

        i.fa {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 150px 20px;
            color: #ececec;
            @include font-size(4);
            position: absolute;
            left: 0;
            top: 0;
            display: none;
            cursor: pointer;
            @media (max-width: 600px) {
                padding: 80px 5px;
                @include font-size(2);
                opacity: 0.8;
            }
            &:hover {
                background-color: rgba(0, 0, 0, 0.8);
                color: $white;
                @media (max-width: 600px) {
                    background-color: rgba(0, 0, 0, 0.8);
                }
            }
        }

        i.fa.fa-chevron-right {
            left: inherit;
            right: 0;
            display: block;
        }
    }
}

section#read {
    padding: 100px 0 75px;

    @media (max-width: 1300px) {
        width: auto;
        margin-left: auto;
    }
    @media (max-width: 600px) {
        padding: 20px 0;
    }

    .billboard-link {
        margin-bottom: 80px;
    }

    div.magazine {
        width: 280px;
        min-height: 490px;
        margin: 12px;
        padding-bottom: 30px;
        position: relative;
        display: inline-block;
        vertical-align: top;

        img {
            width: 100%;
        }

        a.btn {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .reading-products {
        text-align: center;
    }

    .read-content {
        max-width: 1300px;
        margin: 20px auto 0;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-template-rows: repeat(2, 50%);
        @media (max-width: 1000px) {
            display: block;
        }
        @media all and (-ms-high-contrast: none) {
            height: auto;
            text-align: center;
            display: inline-block;
            position: relative;
        }

        a {
            position: relative;
            transition: box-shadow 0.5s;
            @include font-size(0);
            @media (max-width: 1000px) {
                display: block;
            }
            @media all and (-ms-high-contrast: none) {
                width: 25%;
                height: auto;
                margin: auto;
                margin-left: -0.3vw;
                text-align: center;
                position: relative;
                display: inline-block;
                vertical-align: top;
            }

            &:first-of-type {
                grid-column: 1 / span 2;
                grid-row: 1 / span 2;

                img {
                    @media all and (-ms-high-contrast: none) {
                        height: 19vw;
                    }
                }

                h3 {
                    padding-left: 50px;
                    @media (max-width: 600px) {
                        padding: 5vw;
                    }
                }
            }

            &.two-column {
                grid-column: 3 / span 2;
                grid-row: 1 / span 1;

                h3 {
                    padding-left: 50px;
                    @media (max-width: 600px) {
                        padding: 5vw;
                    }
                }
            }

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                @media (max-width: 1000px) {
                    height: auto;
                }
                @media all and (-ms-high-contrast: none) {
                    height: 19vw;
                }
            }

            h3 {
                margin: 0;
                padding: 30px;
                background: linear-gradient(
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0)
                );
                color: $white;
                @include font-size(1.5);
                font-weight: 600;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 9;
                @media (max-width: 1200px) {
                    @include font-size(1.3);
                    line-height: 1;
                }
                @media (max-width: 1000px) {
                    @include font-size(1.5);
                    line-height: 1.5;
                }
                @media (max-width: 600px) {
                    padding: 5vw;
                    font-size: 5vw;
                }

                span {
                    margin-bottom: 10px;
                    color: #ececec;
                    @include font-size(1.1);
                    font-weight: 300;
                    display: block;
                }
            }
        }
    }
}

section#stay-connected {
    max-width: 1360px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    overflow: hidden;
    @media (max-width: 1000px) {
        position: relative;
        display: block;
    }

    .text-wrapper {
        padding: 50px;
        background-color: $black;
        box-shadow: 60px 0px 150px 141px rgba(0, 0, 0, 1);
        color: $white;
        z-index: 9;
        @media (max-width: 1000px) {
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        @media (max-width: 600px) {
            padding: 5vw;
        }

        h2 {
            margin: 50px 0 0;
            font-weight: 600;
            @media (max-width: 600px) {
                margin-top: 1vw;
            }
            @media (max-width: 400px) {
                font-size: 8vw;
            }
        }

        p {
            margin: 10px 0;
            font-weight: 300;
        }

        a.button {
            @media (max-width: 600px) {
                width: 100%;
                margin: 1vw 0;
                padding: 2.5vw 0;
                font-size: 7vw;
            }
        }
    }

    img {
        display: flex;
        align-items: center;
        @media (max-width: 1000px) {
            width: 100%;
            display: block;
        }
    }
}

section#watch {
    padding: 0 0 75px;

    @media (max-width: 800px) {
        padding-bottom: 100px;
    }

    .item-carousel-wrapper {
        margin-bottom: 40px;
        font-size: 0;
        position: relative;
        @media (max-width: 1300px) {
            text-align: center;
        }

        a {
            width: 319px;
            margin-top: 7px;
            margin-left: 7px;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            @media (max-width: 1300px) {
                margin: 7px 3px 0;
            }
            &:nth-of-type(4n + 1) {
                margin-left: 0;
                @media (max-width: 1300px) {
                    margin-left: 3px;
                }
            }
            img {
                width: 100%;
                height: 250px;
            }

            h4 {
                height: 50px;
                margin: 0;
                padding: 20px;
                background-color: $black;
                color: $white;
                @include font-size(1.1);
                font-weight: 600;
                text-align: left;
                position: relative;
                z-index: 9;
            }
        }
    }

    .movie-posters {
        max-width: 1160px;
        margin: auto;
        padding: 0 20px;
        text-align: center;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 25px;

        @media (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 50px 25px;
        }

        .movie-poster {
            h3 {
                margin: 20px auto 10px;
                font-size: 22px;

                @media (max-width: 800px) {
                    margin-top: 12px;
                    font-size: 4vw;
                }
            }

            h4 {
                margin: 0 auto;
                font-size: 20px;
                font-weight: normal;
                font-style: italic;

                @media (max-width: 800px) {
                    font-size: 4.2vw;
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

section#travel {
    padding-bottom: 75px;

    @media (max-width: 800px) {
        padding-bottom: 0;
    }

    .billboard-link {
        .text-wrapper {
            padding-top: 100px;
            background: transparent;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .item-carousel-wrapper {
        margin-bottom: 40px;
        font-size: 0;
        position: relative;
        overflow-x: scroll;
        white-space: nowrap;
        display: none;

        a {
            width: 325px;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            white-space: normal;
            &:last-child {
                margin-right: 0;
            }
            img {
                width: 100%;
                height: 250px;
            }

            h4 {
                height: 50px;
                margin: 0;
                padding: 20px;
                background-color: $black;
                color: $white;
                @include font-size(1.1);
                font-weight: 600;
                text-align: left;
                position: relative;
                z-index: 9;
            }
        }
    }
    .travel-categories {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        @media (max-width: 1000px) {
            display: block;
        }
        @media all and (-ms-high-contrast: none) {
            height: auto;
            text-align: center;
            display: inline-block;
            position: relative;
        }

        a {
            position: relative;
            transition: box-shadow 0.5s;
            @include font-size(0);
            @media (max-width: 1000px) {
                display: block;
            }
            @media all and (-ms-high-contrast: none) {
                width: 25%;
                height: auto;
                margin: auto;
                margin-left: -0.3vw;
                text-align: center;
                position: relative;
                display: inline-block;
                vertical-align: top;
            }

            &:first-of-type {
                grid-column: 1 / span 2;
                grid-row: 1 / span 2;

                img {
                    @media all and (-ms-high-contrast: none) {
                        height: 19vw;
                    }
                }

                h3 {
                    padding-left: 50px;
                    @media (max-width: 600px) {
                        padding: 5vw;
                    }
                }
            }

            &.two-column {
                grid-column: 3 / span 2;
                grid-row: 1 / span 1;

                h3 {
                    padding-left: 50px;
                    @media (max-width: 600px) {
                        padding: 5vw;
                    }
                }
            }

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                @media (max-width: 1000px) {
                    height: auto;
                }
                @media all and (-ms-high-contrast: none) {
                    height: 19vw;
                }
            }

            h3 {
                margin: 0;
                padding: 30px;
                background: linear-gradient(
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0)
                );
                color: $white;
                @include font-size(1.5);
                font-weight: 600;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 9;
                @media (max-width: 600px) {
                    padding: 5vw;
                    font-size: 5vw;
                    line-height: 1;
                }
                span {
                    margin-bottom: 10px;
                    color: #ececec;
                    @include font-size(1.1);
                    font-weight: 300;
                    display: block;
                }
                span.departures {
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: 500;
                    color: #e7e7e7;
                }
            }
        }
    }
}

section#visit {
    padding-bottom: 75px;

    @media (max-width: 800px) {
        padding-bottom: 0;
    }
}

section#at-the-smithsonian {
    padding: 0 0 30px;

    @media (max-width: 800px) {
        padding-top: 0;
    }

    .title-link {
        h2 {
            font-size: 38px;
        }

        p {
            margin: 20px 0 0;
            font-size: 22px;
        }
    }

    .ats-filters {
        max-width: 1180px;
        margin: 20px auto 100px;

        @media (max-width: 1300px) {
            text-align: center;
        }

        .ats-category {
            width: 220px;
            height: 80px;
            margin: 10px 10px 30px;
            padding: 20px;
            border: 4px solid $black;
            color: $black;
            text-align: center;
            display: inline-block;
            cursor: pointer;
            transition: all 0.2s;
            @media (max-width: 1300px) {
                width: 200px;
            }
            @media (max-width: 1100px) {
                width: 110px;
            }
            @media (max-width: 800px) {
                width: 70px;
                padding: 10px 20px;
            }
            @media (max-width: 600px) {
                width: 100%;
                margin: -4px 0 0;
                padding: 10px 0;
                border-left: none;
                border-right: none;
            }
            &:hover,
            &.active {
                background-color: $black;
                color: $white;
            }

            i {
                margin-top: 10px;
                @include font-size(2);
            }

            h3 {
                margin: 5px 0;
                @include font-size(1.5);
                font-weight: 600;
            }
        }

        .ats-filter-item {
            margin: 15px 13px;
            position: relative;
            display: inline-block;
            vertical-align: top;
            a.button {
                margin: 30px 0 0 0;
            }
            @media (max-width: 600px) {
                width: 90%;
                margin: 30px auto 0;
            }
            &:hover {
                h3 {
                    background-color: $yellow;
                }
            }

            h3 {
                width: 315px;
                max-width: 100%;
                height: 50px;
                margin: 0;
                padding: 20px;
                border: 3px solid $yellow;
                @include font-size(1.2);
                font-weight: bold;
                text-align: center;
                line-height: 25px;
                transition: all 0.2s;
                @media (max-width: 600px) {
                    width: auto;
                }
                @media (max-width: 400px) {
                    line-height: 1.5;
                }
            }

            .text-wrapper {
                padding: 30px 20px 40px;
                background-color: #f6f6f6;
                text-align: center;
                position: absolute;
                top: 90px;
                left: 0;
                right: 0;
                display: none;
                z-index: 9;
                @media (max-width: 600px) {
                    position: relative;
                    top: inherit;
                }
                &:hover {
                    display: block;
                }

                h4 {
                    margin: 0;
                    @include font-size(1.3);
                    font-weight: 700;
                }

                p {
                    margin: 0;
                    @include font-size(1.1);
                    line-height: 1.5;
                }

                a.button {
                    width: 200px;
                    border: 3px solid $black;
                    color: $black;
                    @include font-size(1.2);
                    font-weight: 700;
                    &:hover {
                        background-color: $black;
                        color: $white;
                    }
                }
            }

            h3:hover ~ .text-wrapper {
                display: block;
            }
        }
    }
}

section#bottom-promos {
    max-width: 1250px;
    margin: auto;
    padding-bottom: 50px;
    text-align: center;

    .promo {
        width: 510px;
        height: 200px;
        margin: 20px;
        padding: 20px 35px;
        background-size: cover;
        background-repeat: no-repeat;
        color: $white;
        text-align: left;
        position: relative;
        display: inline-block;
        vertical-align: top;
        @media (max-width: 800px) {
            width: 91%;
            margin: 0;
            background-position: top center;
        }
        @media (max-width: 600px) {
            width: 89%;
        }
        @media (max-width: 400px) {
            width: auto;
        }

        h2 {
            margin: 0;
        }

        p {
            margin: 10px 0;
            @include font-size(1.2);
            font-weight: 500;
        }

        a.button {
            width: auto;
            margin: 10px 0;
            padding: 10px 25px;
            border: 2px solid $white;
            color: $white;
            @include font-size(1.2);
            position: absolute;
            bottom: 35px;
            left: 35px;
            @media (max-width: 400px) {
                bottom: 15px;
            }
            &:hover {
                background-color: $white;
                color: $black;
            }
        }
    }
}

#logos {
    background-color: #f2f2f2;
    text-align: center;

    .logos-wrapper {
        max-width: 1420px;
        margin: auto;
        padding: 100px 10px;
        @media (max-width: 1300px) {
            padding: 20px 10px;
        }

        a {
            margin: 42px 35px;
            vertical-align: middle;
            @media (max-width: 1450px) {
                margin: 2vw;
                display: inline-block;
            }
            @media (max-width: 800px) {
                margin: 3vw 5vw;
            }
        }
    }
}

// Newsletter sign up form
.newsletters #mainContent {
    max-width: 1000px;
    margin: auto;
    padding: 50px 20px;
    @media (max-width: 600px) {
        padding: 20px;
    }

    h1 {
        margin-bottom: 0;
        @include font-size(2.5);
        text-align: left;
    }

    p {
        @include font-size(1.1);
    }

    .wufoo#form33 {
        cursor: pointer;
        text-align: center;

        ul {
            padding: 0;
            list-style-type: none;

            fieldset {
                padding: 0;
            }
        }

        fieldset {
            border: none;
        }

        input,
        label {
            @include font-size(1.4);
            font-weight: bold;
            display: inline-block;
            vertical-align: top;

            .choice-description {
                margin-top: 10px;
                @include font-size(1.1);
                font-weight: 300;
            }
        }

        input {
            margin: 12px 10px 0 0;
            @include font-size(2);
            @media (max-width: 600px) {
                width: 15px;
            }
        }

        label {
            width: 270px;
            margin-top: 6px;
            @media (max-width: 600px) {
                width: 74vw;
            }
        }

        .subfield {
            width: 310px;
            margin: 50px 8px 0;
            text-align: left;
            display: inline-block;
            vertical-align: top;
            @media (max-width: 400px) {
                width: auto;
                margin: 50px auto 0;
            }

            img {
                width: 100%;
                margin-bottom: 10px;
                display: block;
            }
        }

        .email-newsletters {
            width: 200%;
            margin: 50px 0 0 -50%;
            padding: 20px;
            background-color: #000;
            text-align: center;
            @media (max-width: 1000px) {
                width: auto;
                margin-left: auto;
            }

            p {
                margin: 20px 0 0;
                color: #fff;
            }

            li {
                width: auto;
                margin: 30px 0 0 30px;
                display: inline-block;
                @media (max-width: 700px) {
                    width: 300px;
                    margin: 30px auto 0;
                    margin-left: auto;
                    display: block;
                }
                @media (max-width: 400px) {
                    width: 200px;
                    min-width: none;
                }
                &:nth-of-type(odd) {
                    margin-left: 0;
                    @media (max-width: 700px) {
                        margin-left: auto;
                    }
                }

                input {
                    width: 600px;
                    padding: 15px 25px;
                    border: 2px solid #000;
                    @include font-size(1.2);
                    vertical-align: top;
                    @media (max-width: 1000px) {
                        width: auto;
                        margin: auto;
                        display: block;
                    }
                    @media (max-width: 400px) {
                        width: 80%;
                        min-width: none;
                    }
                }

                &.buttons input {
                    width: 300px;
                    background-color: #fcd406;
                    border: none;
                    @include font-size(1.5);
                    font-weight: bold;
                    cursor: pointer;
                    @media (max-width: 700px) {
                        width: 265px;
                    }
                }
            }
        }

        li.newsletter-submit {
            text-align: center;
            position: relative;
            display: inline-block;

            input {
                width: 300px;
                margin: 15px auto 50px auto;
                padding: 12px 25px;
                background-color: #fcd406;
                border: none;
                @include font-size(1.5);
                font-weight: bold;
                cursor: pointer;
                transition: all 0.2s;
                &:hover {
                    background-color: #000;
                    color: #fcd406;
                }
                @media (max-width: 700px) {
                    width: 265px;
                }
            }

            #formButton {
                background-color: #000;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 9;
            }
        }

        li.hide {
            visibility: hidden;
        }
    }
}

.footerwrapper {
    background: $white;
    footer {
        width: 1230px;
        max-width: 100%;
        margin: auto;
        padding: 50px 0;
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @media (max-width: 1300px) {
            width: auto;
            padding: 50px;
        }
        @media (max-width: 800px) {
            display: block;
            text-align: center;
        }

        .smith-box {
            width: 180px;
            height: 160px;
            margin: 30px 0;
            padding: 15px;
            background-color: $black;
            color: $white;
            text-align: left;
            grid-column: 1;
            position: relative;
            top: inherit;
            left: inherit;

            div {
                margin: 0;
                @include font-size(2);
                font-weight: 600;
                line-height: 1;
                &:last-of-type {
                    margin: 10px 0 5px;
                    @include font-size(1.5);
                    font-weight: 500;
                }
            }
        }

        .column {
            width: 200px;
            grid-column: 3;
            justify-self: right;
            @media (max-width: 800px) {
                width: auto;
            }

            h3 {
                @include font-size(1.5);
            }

            a {
                margin-bottom: 20px;
                font-weight: 600;
                display: block;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .center-column {
            padding-left: 100px;
            grid-column: 2;
            justify-self: center;
            @media (max-width: 800px) {
                margin: 20px 0 50px;
                padding: 0;
            }
        }
    }
    .footerall {
        text-align: center;
        padding: 5px 10px;
        font-size: 12px;
        @media only screen and (max-width: 400px) {
            hyphens: none;
            strong {
                display: block;
            }
        }
        strong {
            font-weight: normal;
        }
        a {
            color: #000;
            &:hover {
                text-decoration: underline;
            }
        }
        span {
            &:after {
                content: ' | ';
            }
            @media only screen and (max-width: 400px) {
                &:nth-child(4) {
                    &:after {
                        content: '';
                    }
                }
            }
            &:last-of-type {
                &:after {
                    content: '';
                }
            }
        }
    }
}

#mainContent.about {
    padding: 20px;
    h2 {
        font-size: 24px;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    p {
        line-height: 30px;
        font-weight: 300;
        font-size: 17px;
        strong {
            font-weight: 400;
        }
    }
    ul {
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
        list-style: square;
    }
}

#mainContent.flatpage {
    max-width: 1000px;
    margin: auto;
    padding: 80px 30px;
    @media (max-width: 600px) {
        padding: 30px 20px;
    }

    .tagline h1 {
        @include font-size(2.5);
        text-align: center;
    }

    h2 {
        clear: both;
    }

    p {
        margin-bottom: 20px;
        @include font-size(1.1);
        text-align: left;
        line-height: 1.5;
        font-weight: 300;
        strong {
            font-weight: 600;
        }
    }

    ul {
        text-align: left;
    }
    &.carousel {
        padding-top: 50px;
        .faqText {
            margin-top: 50px;
        }
        .article-image {
            margin: 0;
            padding: 0;
            &.left {
                float: left;
                width: 320px;
                margin: 0 20px 20px 0;
                font-size: 0.85rem;
                background: #f5f5f5;
                @media (max-width: 400px) {
                    float: none;
                    width: 100%;
                    margin-right: 0;
                }
                img {
                    width: 100%;
                    height: auto;
                }
                .caption {
                    padding: 5px 15px 10px;
                    line-height: 1.2rem;
                }
            }
            &.right {
                float: right;
                width: 320px;
                margin: 0 0 20px 20px;
                font-size: 0.85rem;
                background: #f5f5f5;
                @media (max-width: 400px) {
                    float: none;
                    width: 100%;
                    margin-left: 0;
                }
                img {
                    width: 100%;
                    height: auto;
                }
                .caption {
                    padding: 5px 15px 10px;
                    line-height: 1.2rem;
                }
            }
        }
    }
}

#mainContent.leadership {
    padding: 50px 30px 30px;
    @media (max-width: 600px) {
        padding: 30px 20px;
    }
}

.leadership-members-wrapper {
    width: 200%;
    margin-left: -50%;
    padding: 100px 0;
    background-color: #f6f6f6;
    @media (max-width: 1100px) {
        width: auto;
        margin-left: auto;
    }

    .leadership-members {
        width: 1030px;
        margin: auto;
        text-align: center;
        @media (max-width: 1100px) {
            width: auto;
        }
    }

    .staff-member {
        width: 266px;
        height: 80px;
        margin: 50px 7px 70px;
        padding: 90px 20px 30px;
        background-color: #fff;
        text-align: center;
        position: relative;
        display: inline-block;
        vertical-align: top;
        @media (max-width: 400px) {
            margin: 50px auto 70px;
        }

        img {
            width: 150px;
            margin: auto;
            border-radius: 100%;
            position: absolute;
            top: -70px;
            left: 0;
            right: 0;
        }

        p {
            margin: 5px auto;
            @include font-size(1.1);
            strong {
                @include font-size(1.2);
            }
        }
    }
}

.three-column-image-grid {
    max-width: 1160px;
    margin: auto;
    padding: 0 20px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;

    @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px 25px;
    }

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }

    img {
        width: 100%;
    }

    h3 {
        margin: 20px auto 10px;
        font-size: 22px;

        @media (max-width: 600px) {
            margin-top: 12px;
            font-size: 5vw;
        }
    }

    p {
        margin: 0;
        font-size: 20px;

        @media (max-width: 600px) {
            font-size: 4.2vw;
        }
    }
}

.image-text-row {
    margin-top: 40px;
    clear: both;
    @media (max-width: 1000px) {
        margin: 20px auto 50px;
        text-align: center;
    }

    img {
        width: 310px;
        max-width: 100%;
        margin-right: 40px;
        display: inline-block;
        vertical-align: top;
        @media (max-width: 1000px) {
            margin: 0 auto 10px;
        }
    }

    .text-wrapper {
        width: 580px;
        display: inline-block;
        @media (max-width: 1000px) {
            width: auto;
            display: block;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        h3 {
            margin-top: 0;
        }
    }
}

#journeys-links {
    max-width: 1300px;
    margin: auto;
    padding: 55px 0;
    text-align: center;

    a {
        width: 280px;
        margin: 15px 12px;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        align-conter: flex-start;
        transition: box-shadow 0.2s;

        img {
            width: 100%;
        }

        h3 {
            height: 120px;
            margin: 0;
            padding: 20px 30px;
            background-color: $black;
            color: $white;
            @include font-size(1.3);
            font-weight: 600;
            display: flex;
            align-self: center;
            font-weight: normal;
            span {
                align-self: center;
                strong {
                    font-weight: bold;
                }
            }
        }
    }
}

.channel-featured {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 25%);
    @media (max-width: 1000px) {
        display: block;
    }
    @media all and (-ms-high-contrast: none) {
        height: auto;
        text-align: center;
        display: inline-block;
        position: relative;
    }
    a {
        position: relative;
        transition: box-shadow 0.5s;
        @include font-size(0);
        @media (max-width: 1000px) {
            display: block;
        }
        @media all and (-ms-high-contrast: none) {
            width: 25%;
            height: auto;
            margin: auto;
            margin-left: -0.3vw;
            text-align: center;
            position: relative;
            display: inline-block;
            vertical-align: top;
        }

        &:first-of-type {
            grid-column: 1 / span 2;
            grid-row: 1 / span 2;

            img {
                @media all and (-ms-high-contrast: none) {
                    height: 19vw;
                }
            }

            h3 {
                padding-left: 50px;
                @media (max-width: 600px) {
                    padding: 5vw;
                }
            }
        }

        &.two-column {
            grid-column: 3 / span 2;
            grid-row: 1 / span 1;

            h3 {
                padding-left: 50px;
                @media (max-width: 600px) {
                    padding: 5vw;
                }
            }
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            @media (max-width: 1000px) {
                height: auto;
            }
            @media all and (-ms-high-contrast: none) {
                height: 19vw;
            }
        }

        h3 {
            margin: 0;
            padding: 30px;
            background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
            color: $white;
            @include font-size(1.5);
            font-weight: 600;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9;
            @media (max-width: 600px) {
                padding: 5vw;
                font-size: 5vw;
                line-height: 1;
            }
            span {
                margin-bottom: 10px;
                color: #ececec;
                @include font-size(1.1);
                font-weight: 300;
                display: block;
            }
            span.departures {
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 500;
                color: #e7e7e7;
            }
        }
    }
}

body.carousel {
    .leaderImage {
        .article-image {
            margin: 0;
            padding: 0;
        }
        .caption {
            font-size: 0.8rem;
            font-style: italic;
            text-align: right;
            @media (max-width: 400px) {
                text-align: center;
            }
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}

.careers {
    .sitecontainer {
        max-width: 100%;
        .leaderImage {
            .desktop-image {
                display: block;
                @media (max-width: 500px) {
                    display: none;
                }
                figure {
                    margin: 0;
                    padding: 0;
                    img {
                        width: 100%;
                        height: auto;
                        position: relative;
                    }
                }
            }
            .mobile-image {
                display: none;
                @media (max-width: 500px) {
                    display: block;
                }
                figure {
                    margin: 0;
                    padding: 0;
                    img {
                        width: 100%;
                        height: auto;
                        position: relative;
                    }
                }
            }
            h1 {
                max-width: 1000px;
                margin: 0 auto;
                color: $white;
                position: relative;
                margin-top: -80px;
                @media (max-width: 500px) {
                    font-size: 1.6rem;
                    padding: 0 20px;
                }
            }
        }
    }
}

.product-submissions {
    .hide {
        display: none;
    }
    .req {
        color: #c00;
    }
    .emaillookup {
        .field {
            padding: 30px 0 20px 0;
            p {
                font-size: 18px !important;
                padding: 4px 10px;
            }
        }
    }
    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
        .field-group-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            margin-bottom: 10px;
            label {
                font-size: 18px;
                font-weight: 700;
            }
            &.name {
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }
    }
    .partner-sites {
        padding-top: 30px;
        margin-top: 20px;
    }
    .contact-information {
        margin-top: 30px;
        h2 {
            font-size: 18px !important;
            font-weight: 700 !important;
            padding: 15px;
        }
    }
    .fieldWrapper {
        display: block;
        padding-bottom: 25px;
        &.twocol {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
        label {
            display: block;
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 5px;
            em {
                font-weight: normal;
            }
        }
        p {
            font-size: 18px !important;
        }
    }
    input[type='text'],
    input[type='email'],
    textarea,
    select {
        width: 100%;
        padding: 7px 7px;
        font-size: 18px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    input[type='checkbox'] {
        display: inline-block;
        width: 25px;
        height: 25px;
    }
    .required {
        color: $red;
    }
    .buttonbox {
        .required-field {
            font-size: 14px;
            margin-bottom: 0;
            margin-top: 20px;
        }
        .centeritem {
            &.submit {
                margin-bottom: 10px;
            }
        }
        .sfmc-info {
            margin-top: 0;
            .preferenceSave {
                margin-bottom: 10px;
            }
        }
    }
}
